// 
// heros.scss
// 

.hero-bottom-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.subscribe-form {
  position: relative;

  .form-control {
    background-color: rgba($white, .12);
    padding: 8px 180px 8px 50px;
    border-radius: 4px;
    min-height: 62px;
    color: $white;
    border-color: transparent;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: $white;
      opacity: 0.3;
    }
  }

  .form-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: $white;
    opacity: .3;
    font-size: 20px;
  }

  .form-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;

    .download-icon {
      display: none;
    }
  }
}


// hero-2
.hero-2 {
  padding: 140px 0 130px;
  //background-image: url("../images/heros/hero-2-bg.png");
  background-image: url("../images/heros/home-back.jpg"); 

  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;

  .hero-2-img {
    position: relative;

    &::after {
      content: "";
      background-image: url("../images/dot.png");
      position: absolute;
      bottom: -30px;
      left: -50px;
      right: auto;
      height: 120px;
      width: 120px;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
}

// hero-3
.hero-3 {
  padding: 170px 0 140px;
  background-image: url("../images/heros/hero-3-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}


// hero-4
.hero-4 {
  padding: 170px 0 140px;
  background-image: url("../images/heros/hero-4-bg.jpg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

// hero-5
.hero-5 {
  padding: 170px 0 450px;
  position: relative;
  background-image: url("../images/heros/hero-5-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
}

.hero-5-img {
  margin-top: -22rem;
}

// hero-5
.hero-6 {
  position: relative;

  .carousel {
    .carousel-inner {
      .carousel-item {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      .hero-slider-arrow {
        font-size: 30px;
        position: relative;

        &::after {
          content: "";
          background-color: rgba($white, 0.12);
          border: 1px solid rgba($white, 0.2);
          height: 50px;
          width: 50px;
          position: absolute;
          left: -8px;
          right: 0;
          top: -2px;
          bottom: 0;
          z-index: -1;
          transform: rotate(45deg);
          border-radius: 6px;
          box-shadow: 0px 0px 0 0px rgba($white, 0.08);
          transition: all 0.3s ease-in-out;

        }
      }

      &:hover {
        .hero-slider-arrow {
          color: $primary;

          &::after {
            background-color: $white;
            border-color: $white;
            box-shadow: 0px 0px 0 10px rgba($white, 0.08);
          }
        }
      }
    }
  }
}


// responsive 
@media (max-width: 991.98px) {
  .hero-2 {
    padding: 90px 0 40px;
  }

  .hero-3 {
    padding: 100px 0 20px;
  }

  .hero-4 {
    padding: 110px 0 20px;
  }

  .hero-6 {
    .carousel {
      .carousel-inner {
        .carousel-item {
          height: auto;
          padding: 120px 0 80px;
        }
      }
    }
  }
}


@media (max-width: 575.98px) {
  .subscribe-form {
    .form-control {
      padding: 8px 64px 8px 50px;
    }

    .form-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      padding: 8px 14px;

      span {
        display: none;
      }

      .download-icon {
        display: block;
      }
    }
  }

  .hero-6 {
    .carousel {
      .carousel-control-prev {
        top: auto;
        left: 10px;
        bottom: 20px;
      }

      .carousel-control-next {
        top: auto;
        right: 10px;
        bottom: 20px;
      }
    }
  }
}


@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-5 {
    padding: 100px 0 420px;
  }
}

@media (max-width: 768px) {
  .hero-5 {
    padding: 100px 0 320px;
  }

  .hero-5-img {
    margin-top: -16rem;
  }
}