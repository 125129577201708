/* Theme Name: Appexy - Responsive bootsrap 5 Landing Page Template
   Author: Coderthemes
   Version: 1.2.0
   Created: Nov 2018
   File Description: Main Css file of the template
*/
/************************
01.General
02.Loader
03.Helper
04.Components
05.Menu
06.Footer
07.Multipurpose Layout
08.Contact
09.Agency Layout
10.Startup layout
11.Business Layout
12.Software Layout
12.App Layout
13.Default
************************/
@import url("https://fonts.googleapis.com/css2?family=Mandali&display=swap");
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../fonts/cerebrisans-light.eot");
  src: local("Cerebri-sans Light"), url("../fonts/cerebrisans-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../fonts/cerebrisans-regular.eot");
  src: local("Cerebri-sans Regular"), url("../fonts/cerebrisans-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../fonts/cerebrisans-medium.eot");
  src: local("Cerebri-sans Medium"), url("../fonts/cerebrisans-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../fonts/cerebrisans-semibold.eot");
  src: local("Cerebri-sans Semibold"), url("../fonts/cerebrisans-semibold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("../fonts/cerebrisans-bold.eot");
  src: local("Cerebri-sans Bold"), url("../fonts/cerebrisans-bold.woff") format("woff");
  font-weight: 700;
}
.bg-primary {
  background-color: #1f61eb !important;
}

.bg-soft-primary {
  background-color: rgba(31, 97, 235, 0.15) !important;
}

.border-primary {
  border-color: #1f61eb !important;
}

.text-primary {
  color: #1f61eb !important;
}

.shadow-primary {
  box-shadow: 0px 5px 14px rgba(31, 97, 235, 0.08);
}

.bg-secondary {
  background-color: #536976 !important;
}

.bg-soft-secondary {
  background-color: rgba(83, 105, 118, 0.15) !important;
}

.border-secondary {
  border-color: #536976 !important;
}

.text-secondary {
  color: #536976 !important;
}

.shadow-secondary {
  box-shadow: 0px 5px 14px rgba(83, 105, 118, 0.08);
}

.bg-info {
  background-color: #4bbbce !important;
}

.bg-soft-info {
  background-color: rgba(75, 187, 206, 0.15) !important;
}

.border-info {
  border-color: #4bbbce !important;
}

.text-info {
  color: #4bbbce !important;
}

.shadow-info {
  box-shadow: 0px 5px 14px rgba(75, 187, 206, 0.08);
}

.bg-warning {
  background-color: #ffc212 !important;
}

.bg-soft-warning {
  background-color: rgba(255, 194, 18, 0.15) !important;
}

.border-warning {
  border-color: #ffc212 !important;
}

.text-warning {
  color: #ffc212 !important;
}

.shadow-warning {
  box-shadow: 0px 5px 14px rgba(255, 194, 18, 0.08);
}

.bg-success {
  background-color: #1ce1ac !important;
}

.bg-soft-success {
  background-color: rgba(28, 225, 172, 0.15) !important;
}

.border-success {
  border-color: #1ce1ac !important;
}

.text-success {
  color: #1ce1ac !important;
}

.shadow-success {
  box-shadow: 0px 5px 14px rgba(28, 225, 172, 0.08);
}

.bg-danger {
  background-color: #eb1f52 !important;
}

.bg-soft-danger {
  background-color: rgba(235, 31, 82, 0.15) !important;
}

.border-danger {
  border-color: #eb1f52 !important;
}

.text-danger {
  color: #eb1f52 !important;
}

.shadow-danger {
  box-shadow: 0px 5px 14px rgba(235, 31, 82, 0.08);
}

.bg-dark {
  background-color: #343a40 !important;
}

.bg-soft-dark {
  background-color: rgba(52, 58, 64, 0.15) !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-dark {
  color: #343a40 !important;
}

.shadow-dark {
  box-shadow: 0px 5px 14px rgba(52, 58, 64, 0.08);
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 250, 0.15) !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.text-light {
  color: #f8f9fa !important;
}

.shadow-light {
  box-shadow: 0px 5px 14px rgba(248, 249, 250, 0.08);
}

.bg-white {
  background-color: #fff !important;
}

.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.border-white {
  border-color: #fff !important;
}

.text-white {
  color: #fff !important;
}

.shadow-white {
  box-shadow: 0px 5px 14px rgba(255, 255, 255, 0.08);
}

.text-muted {
  color: #6c757d !important;
}

body {
  font-family: "Mandali", sans-serif;
  font-size: 16px;
  color: #343a40;
  padding-right: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cerebri Sans,sans-serif";
}

a {
  text-decoration: none !important;
  outline: none;
  color: #1f61eb;
}

p {
  line-height: 1.7;
}

html {
  scroll-padding-top: 59px; /* height of sticky header */
}

.row > * {
  position: relative;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-lg {
  width: 40px;
  height: 40px;
}

.avatar-xl {
  width: 70px;
  height: 70px;
}

.section {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
  position: relative;
}

.section-sm {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
  position: relative;
}

.custom-pt {
  margin-top: 16rem;
}

.bg-overlay {
  position: absolute;
  background-color: #10161e;
  opacity: 0.85;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.subtitle {
  color: #343a40;
  text-transform: uppercase;
  font-weight: normal;
}

.title {
  font-weight: 700;
  font-size: 30px;
  margin-top: 18px;
  line-height: 1.3;
  color: #333;
}

.fw-semibold {
  font-weight: 600;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-38 {
  font-size: 38px;
}

.text-light-custom {
  color: #a2aeb9;
}

.modal-backdrop {
  background-color: #000;
}

.shadow-lg {
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.2) !important;
}

.rounded-lg {
  border-radius: 8px;
}

.right-arrow {
  position: relative;
  top: 2px;
  line-height: 0;
}

/**BACK TO TOP**/
.back-to-top-btn {
  position: fixed;
  padding: 0px 5px !important;
  bottom: 10px;
  right: 20px;
  display: none;
  text-align: center;
  border-radius: 3px;
}
.back-to-top-btn i {
  font-size: 22px;
  line-height: 30px;
}

#mkLightboxContainer {
  z-index: 999;
}

.btn {
  padding: 8px 24px;
  /* border: 1px solid; */
  font-size: 16px;
  transition: all 0.5s;
  font-family: "Mandali", sans-serif;
}
.btn.btn-sm {
  padding: 6px 16px;
}
.btn.btn-lg {
  padding: 12px 28px;
  font-size: 17px;
}
.btn.btn-primary {
  background-color: #1f61eb !important;
  border: 1px solid #1f61eb !important;
  color: #fff !important;
}
.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary.active {
  background-color: #1457e1 !important;
  border: 1px solid #1457e1 !important;
  box-shadow: 0px 8px 12px 0px rgba(31, 97, 235, 0.25);
}
.btn.btn-outline-primary {
  border: 1px solid #1f61eb !important;
  color: #1f61eb !important;
}
.btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus, .btn.btn-outline-primary.focus, .btn.btn-outline-primary:active, .btn.btn-outline-primary.active {
  background-color: #1f61eb !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(31, 97, 235, 0.25);
}
.btn.btn-gradient-primary {
  background: linear-gradient(to right, #1f61eb 0%, #94b4f5 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-secondary {
  background-color: #536976 !important;
  border: 1px solid #536976 !important;
  color: #fff !important;
}
.btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary.focus, .btn.btn-secondary:active, .btn.btn-secondary.active {
  background-color: #4b5e6a !important;
  border: 1px solid #4b5e6a !important;
  box-shadow: 0px 8px 12px 0px rgba(83, 105, 118, 0.25);
}
.btn.btn-outline-secondary {
  border: 1px solid #536976 !important;
  color: #536976 !important;
}
.btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.focus, .btn.btn-outline-secondary:active, .btn.btn-outline-secondary.active {
  background-color: #536976 !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(83, 105, 118, 0.25);
}
.btn.btn-gradient-secondary {
  background: linear-gradient(to right, #536976 0%, #94a8b4 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-info {
  background-color: #4bbbce !important;
  border: 1px solid #4bbbce !important;
  color: #fff !important;
}
.btn.btn-info:hover, .btn.btn-info:focus, .btn.btn-info.focus, .btn.btn-info:active, .btn.btn-info.active {
  background-color: #3bb5ca !important;
  border: 1px solid #3bb5ca !important;
  box-shadow: 0px 8px 12px 0px rgba(75, 187, 206, 0.25);
}
.btn.btn-outline-info {
  border: 1px solid #4bbbce !important;
  color: #4bbbce !important;
}
.btn.btn-outline-info:hover, .btn.btn-outline-info:focus, .btn.btn-outline-info.focus, .btn.btn-outline-info:active, .btn.btn-outline-info.active {
  background-color: #4bbbce !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(75, 187, 206, 0.25);
}
.btn.btn-gradient-info {
  background: linear-gradient(to right, #4bbbce 0%, #afe1e9 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-warning {
  background-color: #ffc212 !important;
  border: 1px solid #ffc212 !important;
  color: #fff !important;
}
.btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning.focus, .btn.btn-warning:active, .btn.btn-warning.active {
  background-color: #fdbc00 !important;
  border: 1px solid #fdbc00 !important;
  box-shadow: 0px 8px 12px 0px rgba(255, 194, 18, 0.25);
}
.btn.btn-outline-warning {
  border: 1px solid #ffc212 !important;
  color: #ffc212 !important;
}
.btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus, .btn.btn-outline-warning.focus, .btn.btn-outline-warning:active, .btn.btn-outline-warning.active {
  background-color: #ffc212 !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(255, 194, 18, 0.25);
}
.btn.btn-gradient-warning {
  background: linear-gradient(to right, #ffc212 0%, #ffe392 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-success {
  background-color: #1ce1ac !important;
  border: 1px solid #1ce1ac !important;
  color: #fff !important;
}
.btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success.focus, .btn.btn-success:active, .btn.btn-success.active {
  background-color: #1acf9e !important;
  border: 1px solid #1acf9e !important;
  box-shadow: 0px 8px 12px 0px rgba(28, 225, 172, 0.25);
}
.btn.btn-outline-success {
  border: 1px solid #1ce1ac !important;
  color: #1ce1ac !important;
}
.btn.btn-outline-success:hover, .btn.btn-outline-success:focus, .btn.btn-outline-success.focus, .btn.btn-outline-success:active, .btn.btn-outline-success.active {
  background-color: #1ce1ac !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(28, 225, 172, 0.25);
}
.btn.btn-gradient-success {
  background: linear-gradient(to right, #1ce1ac 0%, #8cf1d6 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-danger {
  background-color: #eb1f52 !important;
  border: 1px solid #eb1f52 !important;
  color: #fff !important;
}
.btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger.focus, .btn.btn-danger:active, .btn.btn-danger.active {
  background-color: #e11447 !important;
  border: 1px solid #e11447 !important;
  box-shadow: 0px 8px 12px 0px rgba(235, 31, 82, 0.25);
}
.btn.btn-outline-danger {
  border: 1px solid #eb1f52 !important;
  color: #eb1f52 !important;
}
.btn.btn-outline-danger:hover, .btn.btn-outline-danger:focus, .btn.btn-outline-danger.focus, .btn.btn-outline-danger:active, .btn.btn-outline-danger.active {
  background-color: #eb1f52 !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(235, 31, 82, 0.25);
}
.btn.btn-gradient-danger {
  background: linear-gradient(to right, #eb1f52 0%, #f594ac 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-dark {
  background-color: #343a40 !important;
  border: 1px solid #343a40 !important;
  color: #fff !important;
}
.btn.btn-dark:hover, .btn.btn-dark:focus, .btn.btn-dark.focus, .btn.btn-dark:active, .btn.btn-dark.active {
  background-color: #2b3035 !important;
  border: 1px solid #2b3035 !important;
  box-shadow: 0px 8px 12px 0px rgba(52, 58, 64, 0.25);
}
.btn.btn-outline-dark {
  border: 1px solid #343a40 !important;
  color: #343a40 !important;
}
.btn.btn-outline-dark:hover, .btn.btn-outline-dark:focus, .btn.btn-outline-dark.focus, .btn.btn-outline-dark:active, .btn.btn-outline-dark.active {
  background-color: #343a40 !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(52, 58, 64, 0.25);
}
.btn.btn-gradient-dark {
  background: linear-gradient(to right, #343a40 0%, #6d7a86 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-light {
  background-color: #f8f9fa !important;
  border: 1px solid #f8f9fa !important;
  color: #fff !important;
}
.btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light.focus, .btn.btn-light:active, .btn.btn-light.active {
  background-color: #eceff2 !important;
  border: 1px solid #eceff2 !important;
  box-shadow: 0px 8px 12px 0px rgba(248, 249, 250, 0.25);
}
.btn.btn-outline-light {
  border: 1px solid #f8f9fa !important;
  color: #f8f9fa !important;
}
.btn.btn-outline-light:hover, .btn.btn-outline-light:focus, .btn.btn-outline-light.focus, .btn.btn-outline-light:active, .btn.btn-outline-light.active {
  background-color: #f8f9fa !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(248, 249, 250, 0.25);
}
.btn.btn-gradient-light {
  background: linear-gradient(to right, #f8f9fa 0%, white 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-white {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white.focus, .btn.btn-white:active, .btn.btn-white.active {
  background-color: whitesmoke !important;
  border: 1px solid whitesmoke !important;
  box-shadow: 0px 8px 12px 0px rgba(255, 255, 255, 0.25);
}
.btn.btn-outline-white {
  border: 1px solid #fff !important;
  color: #fff !important;
}
.btn.btn-outline-white:hover, .btn.btn-outline-white:focus, .btn.btn-outline-white.focus, .btn.btn-outline-white:active, .btn.btn-outline-white.active {
  background-color: #fff !important;
  color: #fff !important;
  box-shadow: 0px 8px 12px 0px rgba(255, 255, 255, 0.25);
}
.btn.btn-gradient-white {
  background: linear-gradient(to right, #fff 0%, white 100%);
  border: none;
  color: #fff;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}
.btn.btn-light, .btn.btn-white {
  background-color: #fff;
  border: 1px solid #fff;
  color: #1f61eb !important;
}
.btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light.focus, .btn.btn-light:active, .btn.btn-light.active, .btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white.focus, .btn.btn-white:active, .btn.btn-white.active {
  background-color: #ededed !important;
  border-color: #ededed !important;
}
.btn.btn-outline-light, .btn.btn-outline-white {
  border: 1px solid #fff !important;
  color: #fff !important;
  text-transform: uppercase;
}
.btn.btn-outline-light:hover, .btn.btn-outline-light:focus, .btn.btn-outline-light.focus, .btn.btn-outline-light:active, .btn.btn-outline-light.active, .btn.btn-outline-white:hover, .btn.btn-outline-white:focus, .btn.btn-outline-white.focus, .btn.btn-outline-white:active, .btn.btn-outline-white.active {
  background-color: #fff;
  color: #343a40 !important;
}

.modal .video-modal {
  border: 0;
  background-color: transparent;
}
.modal .video-modal .video-box {
  position: relative;
}
.modal .video-modal video {
  border-radius: 8px;
}
.modal .video-modal .btn-close {
  width: 26px;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  z-index: 3;
}
.modal .video-modal .btn-close video:focus {
  outline: none;
}
.modal .video-modal:hover .btn-close {
  opacity: 0.5;
}
.modal .btn-close:focus {
  box-shadow: none;
}

.card {
  border: none;
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}

.navbar-custom {
  padding: 20px 0px;
  padding-right: 0 !important;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
}
.navbar-custom .navbar-nav .nav-item .nav-link {
  position: relative;
  line-height: 26px;
  color: #6c757d;
  font-size: 16px;
  display: inline-block;
  transition: all 0.5s;
  background-color: transparent !important;
  padding: 6px 0;
  margin: 0 18px;
  font-weight: 500;
  font-family: "Mandali", sans-serif;
}
.navbar-custom .navbar-nav .nav-item .nav-link:hover, .navbar-custom .navbar-nav .nav-item .nav-link.active {
  color: #1f61eb;
}
.navbar-custom .navbar-nav .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 10px;
  right: 10px;
  border-radius: 10px;
  border-bottom: 2px solid #1f61eb;
}
.navbar-custom .navbar-nav .nav-item:last-child .nav-link {
  margin-right: 0;
}
.navbar-custom .navbar-nav .nav-item .nav-btn {
  background-color: rgba(31, 97, 235, 0.15);
  border-color: rgba(31, 97, 235, 0.2);
  color: #1f61eb;
  transition: all 0.3s ease-in-out;
}
.navbar-custom .navbar-nav .nav-item .nav-btn:focus {
  box-shadow: none;
}
.navbar-custom .navbar-nav .nav-item .nav-btn:hover {
  background-color: #1f61eb;
  color: #fff;
}

.navbar-expand-lg .navbar-collapse {
  flex-direction: row-reverse;
}
.navbar-custom .navbar-nav .nav-link {
  padding-right: 0px;
  padding-left: 0px;
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #343a40;
}
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: transparent;
  border-color: #1f61eb;
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar.navbar-light .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-light .navbar-nav .nav-item .nav-link.active {
  color: #fff;
}
.navbar.navbar-light .navbar-nav .nav-item .nav-link.active::after {
  border-bottom: 2px solid #fff;
}
.navbar.navbar-light .navbar-nav .nav-item .nav-btn {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.navbar.navbar-light .navbar-nav .nav-item .nav-btn:focus {
  box-shadow: none;
}
.navbar.navbar-light .navbar-nav .nav-item .nav-btn:hover {
  background-color: #fff;
  color: #1f61eb;
}
.navbar.navbar-light .logo .logo-dark {
  display: none;
}
.navbar.navbar-light .logo .logo-light {
  display: block;
}

/********** navbar sticky *********/
.nav-sticky.navbar-custom {
  margin-top: 0px;
  padding: 14px 0px;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  color: #343a40 !important;
}
.nav-sticky.navbar-custom .navbar-nav {
  margin-top: 0px;
}
.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link {
  color: #343a40;
}
.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link:hover, .nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link.active {
  color: #1f61eb !important;
}
.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-link.active::after {
  border-bottom: 2px solid #1f61eb;
}
.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-btn {
  background-color: rgba(31, 97, 235, 0.15);
  border-color: rgba(31, 97, 235, 0.2);
  color: #1f61eb;
}
.nav-sticky.navbar-custom .navbar-nav .nav-item .nav-btn:hover {
  background-color: #1f61eb;
  color: #fff;
}
.nav-sticky .logo .logo-dark {
  display: block !important;
}
.nav-sticky .logo .logo-light {
  display: none !important;
}

/********* Responsive *********/
@media (min-width: 200px) and (max-width: 991px) {
  .navbar-custom, .navbar-light {
    margin-top: 0px;
    padding: 10px 0px !important;
    background-color: #fff !important;
    box-shadow: 0 10px 33px rgba(52, 58, 64, 0.1);
    color: #343a40 !important;
  }
  .navbar-custom .navbar-nav, .navbar-light .navbar-nav {
    margin-top: 0px;
  }
  .navbar-custom .navbar-nav .nav-item .nav-link, .navbar-light .navbar-nav .nav-item .nav-link {
    transition: all 0.4s;
    color: #343a40 !important;
    margin: 0px;
  }
  .navbar-custom .navbar-nav .nav-item .nav-link.active, .navbar-light .navbar-nav .nav-item .nav-link.active {
    color: #1f61eb !important;
  }
  .navbar-custom .navbar-nav .nav-item .nav-btn, .navbar-light .navbar-nav .nav-item .nav-btn {
    background-color: rgba(31, 97, 235, 0.15) !important;
    border-color: rgba(31, 97, 235, 0.2) !important;
    color: #1f61eb !important;
  }
  .navbar-custom .navbar-nav .nav-item .nav-btn:hover, .navbar-custom .navbar-nav .nav-item .nav-btn:focus, .navbar-light .navbar-nav .nav-item .nav-btn:hover, .navbar-light .navbar-nav .nav-item .nav-btn:focus {
    background-color: #1f61eb !important;
    color: #fff !important;
  }
  .navbar-custom > .container, .navbar-light > .container {
    width: 90%;
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #343a40;
  }
  .logo .logo-light {
    display: none !important;
  }
  .logo .logo-dark {
    display: block !important;
  }
}
.footer {
  position: relative;
  padding: 80px 0 30px;
}
.footer .footer-nav li {
  padding: 6px 0;
}
.footer .footer-nav li .footer-link {
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  transition: all 0.3s ease-in-out;
}
.footer .footer-nav li .footer-link::after {
  content: "";
  background-color: #1f61eb;
  position: absolute;
  top: 7px;
  left: 0;
  height: 5px;
  width: 0px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.footer .footer-nav li .footer-link:hover {
  color: #fff;
  padding-left: 12px;
}
.footer .footer-nav li .footer-link:hover::after {
  width: 5px;
}
.footer.bg-dark {
  background-image: url("../images/footer-light-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
.footer .footer-social-icon {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 0 0px rgba(255, 255, 255, 0.1);
}
.footer .footer-social-icon:hover {
  background-color: #fff;
  color: #1f61eb;
  box-shadow: 0px 0px 0 4px rgba(255, 255, 255, 0.1);
}

.footer-tagline {
  background-color: rgba(52, 58, 64, 0.9);
}

.custom-form .form-control {
  height: 45px;
  padding: 0.375rem 1.1rem;
  font-size: 14px;
  border-width: 2px;
}
.custom-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.custom-form textarea.form-control {
  height: auto;
}

.form-control {
  border-color: #e9ecf1;
}
.form-control:focus {
  border-color: #ced4da;
}
.form-control:focus {
  outline: 0;
  box-shadow: none;
}
.form-control::-moz-placeholder {
  font-size: 15px;
  color: #8c99b7;
}
.form-control:-ms-input-placeholder {
  font-size: 15px;
  color: #8c99b7;
}
.form-control::placeholder {
  font-size: 15px;
  color: #8c99b7;
}

label.form-label {
  font-size: 15px;
  font-family: "Cerebri Sans,sans-serif";
  font-weight: 500;
  color: #6c757d;
}

.form-check-label {
  font-size: 15px;
  font-family: "Cerebri Sans,sans-serif";
}

.contact-icon {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 20px;
}

.hero-1 {
  background-color: #1f61eb;
  padding: 170px 0 0px;
  background-image: url("../images/heros/hero-1-bg.png");
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.hero-1 .hero-title {
  font-size: 48px;
  line-height: 1.25;
}
.hero-1 .hero-content {
  padding-bottom: 100px;
}
.hero-1 .down-arrow-btn {
  position: relative;
  z-index: 1;
}
.hero-1 .down-arrow-btn .down-arrow {
  background-color: #fff;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  position: absolute;
  bottom: 0px;
  margin-bottom: 6%;
  box-shadow: 0px 5px 20px 0px rgba(52, 58, 64, 0.1);
}

@media (max-width: 991.98px) {
  .hero-1 {
    background-color: #1f61eb;
    padding: 110px 0 0px;
  }
}
.hero-bottom-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.subscribe-form {
  position: relative;
}
.subscribe-form .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  padding: 8px 180px 8px 50px;
  border-radius: 4px;
  min-height: 62px;
  color: #fff;
  border-color: transparent;
}
.subscribe-form .form-control:focus {
  box-shadow: none;
}
.subscribe-form .form-control::-moz-placeholder {
  color: #fff;
  opacity: 0.3;
}
.subscribe-form .form-control:-ms-input-placeholder {
  color: #fff;
  opacity: 0.3;
}
.subscribe-form .form-control::placeholder {
  color: #fff;
  opacity: 0.3;
}
.subscribe-form .form-icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  color: #fff;
  opacity: 0.3;
  font-size: 20px;
}
.subscribe-form .form-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}
.subscribe-form .form-btn .download-icon {
  display: none;
}

.hero-2 {
  padding: 140px 0 130px;
  background-image: url("../images/heros/hero-2-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}
.hero-2 .hero-2-img {
  position: relative;
}
.hero-2 .hero-2-img::after {
  content: "";
  background-image: url("../images/dot.png");
  position: absolute;
  bottom: -30px;
  left: -50px;
  right: auto;
  height: 120px;
  width: 120px;
  background-repeat: no-repeat;
  z-index: -1;
}

.hero-3 {
  padding: 170px 0 140px;
  background-image: url("../images/heros/hero-3-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.hero-4 {
  padding: 170px 0 140px;
  background-image: url("../images/heros/hero-4-bg.jpg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.hero-5 {
  padding: 170px 0 450px;
  position: relative;
  background-image: url("../images/heros/hero-5-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
}

.hero-5-img {
  margin-top: -22rem;
}

.hero-6 {
  position: relative;
}
.hero-6 .carousel .carousel-inner .carousel-item {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-6 .carousel .carousel-control-prev .hero-slider-arrow,
.hero-6 .carousel .carousel-control-next .hero-slider-arrow {
  font-size: 30px;
  position: relative;
}
.hero-6 .carousel .carousel-control-prev .hero-slider-arrow::after,
.hero-6 .carousel .carousel-control-next .hero-slider-arrow::after {
  content: "";
  background-color: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 50px;
  width: 50px;
  position: absolute;
  left: -8px;
  right: 0;
  top: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(45deg);
  border-radius: 6px;
  box-shadow: 0px 0px 0 0px rgba(255, 255, 255, 0.08);
  transition: all 0.3s ease-in-out;
}
.hero-6 .carousel .carousel-control-prev:hover .hero-slider-arrow,
.hero-6 .carousel .carousel-control-next:hover .hero-slider-arrow {
  color: #1f61eb;
}
.hero-6 .carousel .carousel-control-prev:hover .hero-slider-arrow::after,
.hero-6 .carousel .carousel-control-next:hover .hero-slider-arrow::after {
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0px 0px 0 10px rgba(255, 255, 255, 0.08);
}

@media (max-width: 991.98px) {
  .hero-2 {
    padding: 90px 0 40px;
  }
  .hero-3 {
    padding: 100px 0 20px;
  }
  .hero-4 {
    padding: 110px 0 20px;
  }
  .hero-6 .carousel .carousel-inner .carousel-item {
    height: auto;
    padding: 120px 0 80px;
  }
}
@media (max-width: 575.98px) {
  .subscribe-form .form-control {
    padding: 8px 64px 8px 50px;
  }
  .subscribe-form .form-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    padding: 8px 14px;
  }
  .subscribe-form .form-btn span {
    display: none;
  }
  .subscribe-form .form-btn .download-icon {
    display: block;
  }
  .hero-6 .carousel .carousel-control-prev {
    top: auto;
    left: 10px;
    bottom: 20px;
  }
  .hero-6 .carousel .carousel-control-next {
    top: auto;
    right: 10px;
    bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-5 {
    padding: 100px 0 420px;
  }
}
@media (max-width: 768px) {
  .hero-5 {
    padding: 100px 0 320px;
  }
  .hero-5-img {
    margin-top: -16rem;
  }
}
.work-box .work-icon {
  font-size: 56px;
  border-radius: 28% 72% 50% 50%/26% 20% 80% 74%;
  height: 96px;
  width: 96px;
  margin: 0 auto;
}
.work-box .work-arrow {
  position: absolute;
  right: -56px;
  top: 45%;
  width: 30%;
}

@media (max-width: 991.98px) {
  .work-box .work-arrow {
    display: none;
  }
}
.features-bg {
  background-image: url("../images/features-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.cta-bg {
  background-image: url("../images/counter-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.counter-content {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
}

.screen-slider {
  padding: 50px 0;
}

.screen-slider .swiper-slide {
  position: relative;
  border-radius: 8px;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
  border: 2px solid #eff1f5;
}
.screen-slider .swiper-slide img {
  border-radius: 8px;
}
.screen-slider .swiper-slide.swiper-slide-active {
  transform: scale(1.1);
  box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
  opacity: 1;
}
.screen-slider .swiper-slide.swiper-slide-prev, .screen-slider .swiper-slide.swiper-slide-next {
  opacity: 0.8;
}
.screen-slider .swiper-pagination {
  bottom: -30px;
}
.screen-slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #1f61eb;
  vertical-align: middle;
}
.screen-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  height: 12px;
  width: 12px;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0 1px #1f61eb;
}

.nav.pricing-tab {
  justify-content: center;
  display: table;
  margin: 0 auto;
  background-color: #eff1f5;
  border: 1px solid #e6e9ef;
  padding: 6px;
  border-radius: 50rem;
}
.nav.pricing-tab .nav-item {
  display: inline-block;
}
.nav.pricing-tab .nav-item .nav-link {
  border-radius: 50rem;
  color: #292E49;
  padding: 0.5rem 1.5rem;
}
.nav.pricing-tab .nav-item .nav-link.active {
  background-color: #1f61eb;
  color: #fff;
}

.pricing-box {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(52, 58, 64, 0.08);
  border-radius: 4px;
  padding: 50px;
  position: relative;
  overflow: hidden;
}
.pricing-box .pricing-badge {
  background-color: #eb1f52;
  color: #fff;
  font-size: 13px;
  padding: 2px 40px;
  text-transform: uppercase;
  position: absolute;
  top: 40px;
  right: -40px;
  transform: rotate(45deg);
  box-shadow: 0 2px 8px rgba(16, 22, 30, 0.3);
}

.pt-30 {
  padding-top: 30px;
}

.faqs-accordion .accordion-item {
  border-bottom: none;
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(52, 58, 64, 0.08);
}
.faqs-accordion .accordion-item .accordion-header .accordion-button {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: none !important;
  color: #343a40;
  font-size: 17px;
  font-weight: 500;
}
.faqs-accordion .accordion-item .accordion-header .accordion-button.collapsed {
  border-radius: 6px;
  color: #536976;
}
.faqs-accordion .accordion-item .accordion-header .accordion-button.collapsed:before {
  color: #536976;
  transform: rotate(0deg);
}
.faqs-accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.faqs-accordion .accordion-item .accordion-header .accordion-button::after {
  display: none;
}
.faqs-accordion .accordion-item .accordion-header .accordion-button:before {
  content: "\eb73";
  font-family: IcoFont;
  display: inline-block;
  font-size: 22px;
  color: #343a40;
  position: absolute;
  right: 20px;
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
.faqs-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #e6e9ef;
  color: #343a40;
}/*# sourceMappingURL=style.css.map */

.logo img {max-width:270px;}
.footer-logo img {max-width:200px;}