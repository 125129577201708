// avatar.scss

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-lg {
    width: 40px;
    height: 40px;
}

.avatar-xl {
    width: 70px;
    height: 70px;
}
