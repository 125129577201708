// how-it-work


.work-box {
    .work-icon {
        font-size: 56px;
        border-radius: 28% 72% 50% 50% / 26% 20% 80% 74%;
        height: 96px;
        width: 96px;
        margin: 0 auto;
    }
    .work-arrow {
        position: absolute;
        right: -56px;
        top: 45%;
        width: 30%;
    }
}


// responsive 
@media (max-width: 991.98px) {
    .work-box {
        .work-arrow {
            display: none;
        }
    }
}
