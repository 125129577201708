// @import "bootstrap.scss";
@import "~bootstrap-scss/bootstrap.scss";
@import "styles/color.scss";
@import "styles/themify.scss";
@import "styles/whether-icon/whether-icon";
@import "styles/flag-icon/flag-icon-base";
@import "styles/flag-icon/flag-icon-more";
@import "styles/flag-icon/flag-icon-list";
@import "styles/icoicon/icons";
@import "styles/font-awesome.scss";
@import "styles/sticky/sticky";
@import "styles/slick.scss";
@import "styles/slick-theme.scss";
@import "~react-toastify/dist/ReactToastify.css";
@import "/node_modules/react-datepicker/dist/react-datepicker.css";

//  ImageCrop

@import "/node_modules/react-image-crop/dist/ReactCrop.css";
@import "/node_modules/easymde/dist/easymde.min.css";

// Gallery Light Box

@import "styles/chartist/chartist-settings";
@import "styles/chartist/chartist";

@import "~react-perfect-scrollbar/dist/css/styles.css";
